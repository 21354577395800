import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export function useProductUpdateNew() {
  const { error } = useNotification();

  const { mutate, loading, onDone, onError } = useMutation(PRODUCT_UPDATE);

  onError((err) => {
    console.error('useProducts', err);
    error();
  });

  return { mutate, loading, onDone, onError };
}

const PRODUCT_UPDATE = gql`
  mutation productUpdate($id: ID!, $productData: ProductInputNew!) {
    productUpdateNew(id: $id, params: $productData) {
      id
    }
  }
`;
