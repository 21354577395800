import { gql } from '@apollo/client/core';
import { computed } from 'vue';
import { useQuery, useResult } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export function useProduct(productId) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(
    PRODUCT_QUERY,
    () => ({ productId: productId.value }),
    () => ({
      enabled: !!productId.value,
    })
  );

  const product = useResult(result);

  onError((err) => {
    console.error('useProduct', err);
    error();
  });

  return {
    product,
    refetch,
    loading,
  };
}

const PRODUCT_QUERY = gql`
  query product($productId: ID!) {
    product(id: $productId) {
      id
      name
      sku
      gtin
      uom
      categoryId
      bom {
        uom
        quantity
        bom {
          uom
          quantity
        }
      }
      business {
        id
        name
      }
      references {
        name
        sku
      }
      associatedProducts {
        productId
        quantity
        integralToParent
        partOf
        product {
          id
          name
          sku
        }
      }
    }
  }
`;

export function useProductNew(productId) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(
    PRODUCT_NEW_QUERY,
    () => ({ productId: productId.value }),
    () => ({
      enabled: !!productId.value,
    })
  );

  const product = computed(() => result.value?.productNew ?? null);

  onError((err) => {
    console.error('useProduct', err);
    error();
  });

  return {
    product,
    refetch,
    loading,
  };
}

const PRODUCT_NEW_QUERY = gql`
  query productNew($productId: ID!) {
    productNew(id: $productId) {
      id
      name
      sku
      gtin
      categoryId
      createdAt
      bom {
        uom
        quantity
        orderUnit
        billingUnit
        bom {
          uom
          quantity
          billingUnit
          bom {
            uom
            quantity
            billingUnit
          }
        }
      }
      business {
        id
        name
      }
      references {
        name
        sku
      }
      associatedProducts {
        productId
        quantity
        integralToParent
        partOf
        product {
          id
          name
          sku
        }
      }
    }
  }
`;
