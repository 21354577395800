import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export function useLatestOrderItemsForMapTask(variables) {
  const { error } = useNotification();

  const { result, refetch, loading, onError } = useQuery(LATEST_ORDER_ITEMS_FOR_MAP_TASK, variables, () => ({
    enabled: !!variables.value?.businessId,
  }));

  const orderItems = computed(() => (result.value ? result.value.orderItems?.nodes ?? [] : []));

  onError((err) => {
    console.error('useLatestOrderItemsForMapTask', err);
    error();
  });

  return {
    orderItems,
    refetch,
    loading,
  };
}

const LATEST_ORDER_ITEMS_FOR_MAP_TASK = gql`
  query latestOrderItemsForMapTask($businessId: ID, $productId: ID, $limit: Int) {
    orderItems(businessId: $businessId, productId: $productId, limit: $limit) {
      nodes {
        totalAmount
        quantity
        id
        orderDate
        orderId
        price
        orderSource {
          documentNew2 {
            id
          }
        }
      }
    }
  }
`;
