import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';

export function useProductCreate(productData) {
  const { mutate, loading, onDone, onError } = useMutation(PRODUCT_CREATE, productData);
  return { mutate, loading, onDone, onError };
}

const PRODUCT_CREATE = gql`
  mutation product($productData: ProductInput!) {
    productCreate(params: $productData) {
      id
      name
      sku
      businessId
      categoryId
      gtin
      references {
        sku
        name
      }
      uom
      bom {
        uom
        quantity
        bom {
          uom
          quantity
        }
      }
    }
  }
`;
