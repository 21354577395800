import { ref } from 'vue';

import { useNotification } from '@/modules/core';
import { retrieveCategoryTrees } from '@/modules/products/compositions/products-operations';

export const useCategories = () => {
  const { error } = useNotification();

  const categoryTrees = ref([]);
  const loading = ref(true);

  if (categoryTrees.value.length === 0)
    retrieveCategoryTrees()
      .then((responseTree) => {
        categoryTrees.value = responseTree;
        loading.value = false;
      })
      .catch((err) => {
        console.error('CATEGORY', err);
        error();
      });

  return {
    categoryTrees,
    loading,
    getCategory: (categoryId) => getCategory(categoryTrees.value, categoryId),
  };
};

const getBranchNames = (categoryTree, categoryId) => {
  if (categoryTree._id === categoryId) return [categoryTree.name];
  for (const child of categoryTree.children || []) {
    const branch = getBranchNames(child, categoryId);
    if (branch) return [categoryTree.name, branch];
  }
};

const getCategory = (categoryTrees, categoryId) => {
  if (!categoryId) return;
  for (let tree of categoryTrees) {
    const branch = getBranchNames(tree, categoryId);
    if (branch) return branch.join('/');
  }
};
