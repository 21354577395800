import { computed } from 'vue';
import { DateTime } from 'luxon';
import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export function useFallbackDocumentsForDefineProduct(product, enabled) {
  const { error } = useNotification();

  const variables = computed(() => {
    if (!product.value) return;
    const createdAt = DateTime.fromMillis(product.value?.createdAt);
    const recordedAtFrom = createdAt.toISODate();
    const recordedAtTo = createdAt.plus({ days: 2 }).toISODate();

    return {
      supplierId: product.value?.business?.id,
      recordedAtFrom,
      recordedAtTo,
    };
  });

  const { result, loading, onError, refetch } = useQuery(FALLBACK_DOCUMENTS_QUERY, variables, () => ({
    enabled: !!enabled.value,
  }));

  const document = computed(() => {
    if (!product.value) return {};
    const productId = product.value?.id;
    if (result?.value?.documentsNew2?.nodes?.length) {
      for (const docIter of result.value.documentsNew2.nodes) {
        const itemExistsInDocument = (docIter?.items || []).findIndex((item) => item.productId === productId) !== -1;
        if (itemExistsInDocument) {
          return docIter;
        }
      }
    }

    return {};
  });

  onError((err) => {
    console.error('useFallbackDocumentsForDefineProduct', err);
    error();
  });

  return {
    document,
    refetch,
    loading,
    onError,
  };
}

const FALLBACK_DOCUMENTS_QUERY = gql`
  query fallbackDocumentsForDefineProduct($supplierId: ID, $recordedAtFrom: Timestamp, $recordedAtTo: Timestamp) {
    documentsNew2(supplierId: $supplierId, recordedAtFrom: $recordedAtFrom, recordedAtTo: $recordedAtTo) {
      totalCount
      nodes {
        id
        filePathUrl
        items {
          productId
        }
      }
    }
  }
`;
