import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export function useProductsForMapTask(ids) {
  const { error } = useNotification();

  const { result, refetch, loading, onError } = useQuery(PRODUCTS_QUERY_NEW, () => ({ ids: ids.value }));

  const products = computed(() => (result.value ? result.value.productsNew3?.nodes ?? [] : []));

  onError((err) => {
    console.error('useProductsForMapTask', err);
    error();
  });

  return {
    products,
    refetch,
    loading,
  };
}

const PRODUCTS_QUERY_NEW = gql`
  query productsForMapTask($ids: [ID!]) {
    productsNew3(ids: $ids) {
      nodes {
        id
        name
        sku
        business {
          name
        }
        bom {
          quantity
          uom
          orderUnit
          billingUnit
          bom {
            quantity
            uom
            orderUnit
            billingUnit
            bom {
              quantity
              uom
              orderUnit
              billingUnit
            }
          }
        }
      }
    }
  }
`;
