import { computed } from 'vue';
import { useNotification } from '@/modules/core';
import { useQuery } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';

export function useProductOriginDocument(productId) {
  const { error } = useNotification();

  const { result, refetch, loading, onError } = useQuery(
    PRODUCT_ORIGIN_DOCUMENT,
    () => ({ productId: productId.value }),
    () => ({
      enabled: !!productId.value,
    })
  );

  const documentFilePathUrl = computed(() =>
    result.value
      ? result.value.orderAggregation?.groupOrderItems?.nodes[0]?.firstOrder?.order?.eventReferences[0]?.document
          ?.filePathUrl ?? ''
      : ''
  );

  onError((err) => {
    console.error('useProductOriginDocument', err);
    error();
  });

  return {
    documentFilePathUrl,
    loading,
    refetch,
  };
}

const PRODUCT_ORIGIN_DOCUMENT = gql`
  query productOriginDocument($productId: ID) {
    orderAggregation {
      groupOrderItems(productId: $productId) {
        nodes {
          firstOrder {
            order {
              eventReferences {
                document {
                  filePathUrl
                  filePath
                }
              }
            }
          }
        }
      }
    }
  }
`;
