import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';

export function useProductPatch() {
  const { mutate, loading, onDone, onError } = useMutation(PRODUCT_PATCH);
  return { mutate, loading, onDone, onError };
}

const PRODUCT_PATCH = gql`
  mutation product($id: ID!, $productData: ProductPatchInput!) {
    productPatch(id: $id, params: $productData) {
      id
      name
      sku
      gtin
      businessId
      categoryId
      references {
        sku
        name
      }
      uom
      bom {
        uom
        quantity
        bom {
          uom
          quantity
        }
      }
    }
  }
`;
