import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';
import { ref, computed } from 'vue';

import { useNotification, useLoading } from '@/modules/core';

export function useDocumentForProductMapTask(id) {
  const { error } = useNotification();

  const {
    result,
    loading: queryLoading,
    onError,
    refetch,
  } = useQuery(DOCUMENT_QUERY_NEW2, { id }, () => ({ enabled: !!id.value }));

  const document = computed(() => result?.value?.documentNew2 ?? {});
  const variables = ref({ id });
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('useDocumentForProductMapTask', err);
    error();
  });

  return {
    document,
    refetch,
    loading,
    onError,
  };
}

const DOCUMENT_QUERY_NEW2 = gql`
  query useDocumentForProductMapTask($id: ID!) {
    documentNew2(id: $id) {
      createdAt
      id
      filePathUrl
      businessId
      issueDate
      documentNumber
      allocationNumber
      supplier {
        id
        name
      }
      items {
        productId
        quantity
        price
      }
    }
  }
`;
