import { gql } from '@apollo/client/core';
import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export function useProductsNew(variables) {
  const { error } = useNotification();

  const { onResult, result, loading, refetch, onError } = useQuery(PRODUCTS_NEW_QUERY, variables);
  const productConnection = computed(() => (result.value ? result.value.productsNew : { nodes: [], totalCount: 0 }));

  const totalCount = computed(() => productConnection.value.totalCount);

  const products = computed(() => productConnection.value.nodes);

  onError((err) => {
    console.error('useProducts', err);
    error();
  });

  return {
    products,
    loading,
    totalCount,
    refetch,
    onResult,
  };
}

export const PRODUCTS_NEW_QUERY = gql`
  query productsNew($limit: Int, $offset: Int, $search: String, $businessId: ID) {
    productsNew(limit: $limit, offset: $offset, search: $search, businessId: $businessId) {
      totalCount
      nodes {
        id
        name
        sku
        gtin
        categoryId
        associatedProducts {
          productId
          quantity
        }
        business {
          id
          name
        }
        references {
          name
          sku
        }
      }
    }
  }
`;
