import { ref, computed, watch } from 'vue';
import { useNotification } from '@/modules/core';
import { gql } from '@apollo/client/core';
import { apolloClient } from '@/plugins/apollo-client';

export function useGroupOrderItemsByProduct(variables, limit = 200) {
  const { error } = useNotification();
  const allResults = ref([]);
  const loading = ref(false);
  const productIds = computed(() => variables.value.productIds);

  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  const fetchAllData = async () => {
    if (!productIds.value.length) return;

    loading.value = true;
    const productChunks = chunkArray(productIds.value, limit);
    const promises = productChunks.map((chunk) => {
      return apolloClient.query({
        query: GROUP_ORDER_ITEMS_BY_PRODUCT_QUERY,
        variables: {
          ...variables.value,
          productIds: chunk,
        },
        fetchPolicy: 'no-cache',
      });
    });

    try {
      const results = await Promise.all(promises);
      results.forEach(({ data }) => {
        if (data && data.orderAggregation && data.orderAggregation.groupOrderItemsByProduct) {
          allResults.value = [...allResults.value, ...data.orderAggregation.groupOrderItemsByProduct.nodes];
        } else {
          console.error('Unexpected data structure:', data);
        }
      });
    } catch (e) {
      console.error('useGroupOrderItemsByProduct Error fetching data:', e);
      error();
    } finally {
      loading.value = false;
    }
  };

  watch(
    productIds,
    (newProductIds, oldProductIds) => {
      if (JSON.stringify(newProductIds) !== JSON.stringify(oldProductIds) && productIds.value.length > 0) {
        allResults.value = [];

        fetchAllData();
      }
    },
    {
      immediate: true,
      deep: true,
    }
  );

  const groupOrderItemsByProduct = computed(() => allResults.value);

  return {
    groupOrderItemsByProduct,
    loading,
  };
}

const GROUP_ORDER_ITEMS_BY_PRODUCT_QUERY = gql`
  query orderItemAggregationByProduct($businessId: ID, $productIds: [ID]) {
    orderAggregation {
      groupOrderItemsByProduct(businessId: $businessId, productIds: $productIds) {
        nodes {
          productId
          lastOrder {
            id
            date
            order {
              eventReferences {
                documentId
              }
            }
          }
        }
      }
    }
  }
`;
