import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery, useResult } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export function useSuppliers() {
  const { error } = useNotification();
  const { result, loading, refetch, onError } = useQuery(
    SUPPLIERS_QUERY,
    () => ({}),
    () => ({ fetchPolicy: 'cache-first' })
  );
  const suppliers = useResult(result, []);

  onError((err) => {
    console.error('useSuppliers', err);
    error();
  });

  return { suppliers, loading, refetch };
}

export function useSuppliersNew() {
  const { error } = useNotification();
  const { result, loading, refetch, onError } = useQuery(
    SUPPLIERS_NEW_QUERY,
    () => ({}),
    () => ({ fetchPolicy: 'cache-first' })
  );
  const suppliers = computed(() => (result.value ? result.value.suppliersNew?.nodes ?? [] : []));

  onError((err) => {
    console.error('useSuppliersNew', err);
    error();
  });

  return { suppliers, loading, refetch };
}

export const SUPPLIERS_NEW_QUERY = gql`
  query suppliers {
    suppliersNew {
      nodes {
        name
        id
        number
      }
    }
  }
`;

export const SUPPLIERS_QUERY = gql`
  query suppliers {
    suppliers {
      name
      id
      number
    }
  }
`;
