import { gql } from '@apollo/client/core';

import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export function useProductsNumberOfOrders(variables) {
  const { error } = useNotification();

  const { result, loading, onError, refetch, onResult } = useQuery(PRODUCTS_NUMBER_OF_ORDERS, variables, () => ({
    enabled: !!variables.value.tenantId,
  }));

  const productsNumberOfOrders = computed(() => (result.value ? result.value.orderItemAggregationNew.nodes ?? [] : []));

  onError((err) => {
    console.error('useCatalogs', err);
    error();
  });

  return {
    productsNumberOfOrders,
    refetch,
    loading,
    onResult,
  };
}

export const PRODUCTS_NUMBER_OF_ORDERS = gql`
  query productsNumberOfOrders($tenantId: ID!, $supplierId: ID!) {
    orderItemAggregationNew(tenantId: $tenantId, supplierId: $supplierId, aggregateBy: "product") {
      nodes {
        productId
        numberOfOrders
      }
    }
  }
`;
