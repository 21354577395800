import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';

import { useNotification } from '@/modules/core';

export function useDocumentFilePath(id) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(DOCUMENT_QUERY_NEW2, { id }, () => ({ enabled: !!id.value }));

  const document = computed(() => result?.value?.documentNew2 ?? {});

  onError((err) => {
    console.error('useDocumentFilePath', err);
    error();
  });

  return {
    document,
    refetch,
    loading,
    onError,
  };
}

const DOCUMENT_QUERY_NEW2 = gql`
  query useDocumentFilePath($id: ID!) {
    documentNew2(id: $id) {
      filePathUrl
    }
  }
`;
