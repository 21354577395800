import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export const useCategoriesGraph = (parentId) => {
  const { error } = useNotification();

  const { result, refetch, loading, onError } = useQuery(
    CATEGORIES_QUERY,
    () => ({ parentId: parentId.value }),
    () => ({
      enabled: !!parentId.value,
    })
  );

  const categoryTrees = computed(() => result?.value?.categories ?? []);

  onError((err) => {
    console.error('useCategoriesGraph', err);
    error();
  });

  return {
    categoryTrees,
    loading,
    refetch,
    getCategory: (categoryId) => getCategory(categoryTrees.value, categoryId),
  };
};

const getBranchNames = (categoryTree, categoryId) => {
  if (categoryTree._id === categoryId) return [categoryTree.name];
  for (const child of categoryTree.children || []) {
    const branch = getBranchNames(child, categoryId);
    if (branch) return [categoryTree.name, branch];
  }
};

export const getCategory = (categoryTrees, categoryId) => {
  if (!categoryId) return;
  for (let tree of categoryTrees) {
    const branch = getBranchNames(tree, categoryId);
    if (branch) return branch.join('/');
  }
};

const CATEGORIES_QUERY = gql`
  query categories($parentId: String) {
    categories(parentId: $parentId) {
      id
      name
      parentId
    }
  }
`;
